import React, {Component} from 'react';
//import logo from '../logo.svg';
//import webddn from '../WEBDDN.svg';
import WEDDNICONLOGO from '../images/WEDDNICONLOGO.svg';
import ANANSEFCLOGO from '../images/anansefclogo.svg';

//import webddntech from '../images/DDNTECHNOLOGY2.png';
import {withRouter} from 'react-router-dom';

function CurrentPowerDate() {
  var tempDate = new Date();
  //var date = tempDate.getFullYear() + '-' + (tempDate.getMonth()+1) + '-' + tempDate.getDate() +' '+ tempDate.getHours()+':'+ tempDate.getMinutes()+':'+ tempDate.getSeconds();
  const currDate = tempDate.getFullYear() ;
  return (
  currDate
  );
}

class Footer extends Component {

  constructor(props) {

console.log(props)
    super(props)
  }
  render()
  {


return(
  <footer className="ddnFooter">
      <div  className="ddnFooterDiv" >
        <div>
          {/*this.props.location.pathname*/}
        </div>

          Powered by  <div className="ddnPower">     </div> <img src={WEDDNICONLOGO} className="ddnPowerLogo" alt="logo" /> © 2010-<CurrentPowerDate />

      </div>
    </footer>
    )
  }

}
export default withRouter(Footer);
