import React from 'react'
import { Switch, Route } from 'react-router-dom'
import About from './components/About'
import Academics from './components/Academics'
import Mission from './components/Mission'
import Vision from './components/Vision'
import Football from './components/Football'
import Contact from './components/Contact'
import Landing from './components/Landing'
import {withRouter} from 'react-router-dom';


const Main = (props) =>
(


    <main className={props.ddnstyle} >
      <Switch>
        <Route path='/about' component={About}/>
        <Route path='/academics' component={Academics}/>
        <Route path='/mission' component={Mission}/>
        <Route path='/vision' component={Vision}/>
        <Route path='/football' component={Football}/>
        <Route path='/contact' component={Contact}/>
        <Route
          exact path='/'
          render={(routeProps) =>( <Landing {...props} testr={props.ddnLanding} isAuthed={true}/>)}
        />
      </Switch>
    </main>
  )

export default Main
