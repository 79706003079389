//import { combineReducers } from 'redux'
import {
  REQUEST_POST,
  RECIEVE_POST,
} from '../actions/action'

const postReducer = (state =[], action) =>{
  switch (action.type) {
    case 'REQUEST_POST':
      return state.concat([action.data])
    case 'RECIEVE_POST':
        return state.concat([action.data]);
    default:
      return state;


  }
}
export default postReducer;
