import React, { Component }  from 'react'
import playingsoccer23 from '../images/giphy.gif';

export default class Contact extends Component {



  render(){
    function createMarkup() {
      return {__html: 'IMAGINE with all your mind.</br></br> BELIEVE with all your heart.​</br></br> ACHIEVE with all your might.'};
    }
    return(
      <div className="App-content-landing-container" >
      <div className="App-content-landing" >
        <div className="App-promo" >
          <img src={playingsoccer23} className="App-title-reg-landing" alt="WEB DDN TECHNOLOGY" />
        </div>
        <div className="App-promo-text" >
          <div className="text-headline" >
          Contact
          </div>
          <p>
          Murphy​​
          </p>
          Tel: +1 (416) 522-2693
          <p>
          Jesse
          </p>
          <p>
          Tel: +1 (647) 678-4040
          </p>          ​
          <p>
          anansefc@gmail.com
          </p>
        </div>
      </div>
      </div>
    )
  }
}
