import React, {Component} from 'react';
import logo from '../logo.svg';
import webddn from '../WEBDDN.svg';
//import { Route } from 'react-router';
//import {withRouter} from 'react-router-dom';
import playingsoccer from '../images/soccer.mp4';
import '../nav.css';
export default class Nav extends Component {

  constructor(props) {

console.log(props)
    super(props)
  }
  render()
  {

  //if(this.props.location.pathname === "/")
    return (
      <div class="nav_containter">
      <label for="show-menu" class="show-menu">&#9776;
      </label>
      <input type="checkbox" id="show-menu" role="button"></input>
      <ul id="menu">
          <li><a href="/">Home</a></li>
          <li>
              <a href="#">About ￬</a>
              <ul class="hidden">
                  <li><a href="/mission">Mission statement</a></li>
                  <li><a href="/vision">Vision statement</a></li>
              </ul>
          </li>
          <li>
              <a href="/academics">Academics</a>
          </li>
          <li><a href="/football">Football</a></li>
          <li><a href="/contact">Contact</a></li>
      </ul>
      </div>
    )
    /*
 else
    return(
      <div>
      <video class="ddn_max_width" class="fullscreen-bg__video"  autoplay="true" loop muted>
        <source src={playingsoccer} type="video/mp4" />
      </video>
<mat-icon class="mat-icon" role="img" svgicon="menu" aria-hidden="true"><svg focusable="false" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z"></path></svg></mat-icon>
      <img src={logo} className="App-logo-small" alt="logo" />
      <img src={webddn} className="App-title-small" alt="WEB DDN" />
  {this.props.location.pathname}






      </div>
    )
    */
  }


}
