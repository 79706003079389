import React, { Component }  from 'react'
//import logo from '../logo.svg';
//import webddn from '../WEBDDN.svg';
import webddnresponsive from '../images/responsive_design_ddn.png';
import webddntech from '../images/DDNTECHNOLOGY2.png';
import webddniconlogo from '../images/anansefclogo.svg';
import pwa from '../images/platform-pwa-progressive-web-app.svg';
import electronjs from '../images/platform-electronjs-desktop.svg';
import appleios from '../images/platform-apple-ios.svg';
import androidgoogle from '../images/platform-android-google.svg';
import javascripticon from '../images/platform-javascript.svg';
import windowsicon from '../images/platform-windows.svg';
import buyerpromotion from '../images/Buyer-Promotion.png';
import carousel_image1 from '../images/hiltop.webp';
import playingsoccer from '../images/soccer.mp4';
import playingsoccer2 from '../images/34918151103_c5e0fda3d3_b.jpg';
import playingsoccer3 from '../images/soccer.gif';
import playingsoccer4 from '../images/e0343d46b9f67b03603b3de69cf334b2.gif';
import playingsoccer5 from '../images/stop_and_go_large.gif';
import playingsoccer6 from '../images/13920601812769.png';
import playingsoccer7 from '../images/8iEab5yia.png';
import playingsoccer8 from '../images/action-athletes-ball-1375152.jpg';
import playingsoccer9 from '../images/action-adult-athlete-1311619.jpg';
import playingsoccer10 from '../images/athlete-ball-fight-2209.jpg';
import playingsoccer11 from '../images/soccer-field-1147736.jpg';
import playingsoccer12 from '../images/athlete-ball-boy-1866654.jpg';
import playingsoccer13 from '../images/action-active-athlete-1311616.jpg';
import playingsoccer14 from '../images/Freddy_Adu_20110622.jpg';





import {withRouter} from 'react-router-dom';
import ReactSwipe from 'react-swipe';

const images = require.context('../images', true);
let imgsrc
let reactSwipeEl;
let imgsrc2 = 'hiltop.webp'
// Import all images in image folder
/*
function importAll(r) {
    let images = {};
    r.keys().map((item, index) => { images[item.replace('./', '')] = r(item); });
    return images;
}


const images2 = importAll(require.context('../images', false, /\.(gif|jpe?g|svg)$/));
*/
export default class Landing extends Component {
/*
  constructor(props) {
    console.log('LANDING props');
    console.log(props.ddnLanding);

    super(props)
    console.log('LANDING props');
    console.log(props);

    this.state = {
      landing: props
    };

    //did it with css instead
    //this.state = {
    //  width: window.innerWidth,
  //  };
  }
  */

  // Require context image folder
  multiplyES6 = () => { return 2 * 2 };
  ddnHero = (ddnTextHeadline,image,ddnTextSlogan) => (
      <div className="ddnBanner">
        <img src={images(`./${image}`)} className="App-icon-reg-landing" alt="logo" />
        <div className="ddnTextHeadline">
          {ddnTextHeadline}
        </div>
          <div className="ddnTextSlogan">
            {ddnTextSlogan}
          </div>
      </div>

  );
  ddnbuttonContanier = (text,href) => (
    <div className="ddnbuttonContanier">
      <a className="ddnbutton" href={href}>{text}</a>
    </div>

  );
  //image,headline,desicption
  ddnContent = (image,headline,desicption) => (
    <div className="App-content-landing" >
      <div className="App-promo" >
        <img src={images(`./${image}`)} className="App-title-reg-landing" alt="WEB DDN TECHNOLOGY" />
      </div>
      <div className="App-promo-text" >
        <div className="text-headline" >
          {headline}
        </div>
        <p>
          {desicption}
       </p>
      </div>
    </div>
);
 typeOf = (obj) => {
  return {}.toString.call(obj).split(' ')[1].slice(0, -1).toLowerCase();
}
vals = (countries) => {
  Object.keys(countries).map(
    function(key) {
      console.log('key')

      console.log(key)
      console.log('countries[key]')

      console.log(countries[key])

      return countries[key];
    }
  )
}
  componentWillMount() {

  }

  // make sure to remove the listener
  // when the component is not mounted anymore
  componentWillUnmount() {

  }



render(){
  function createMarkup() {
    return {__html: 'IMAGINE with all your mind.</br></br> BELIEVE with all your heart.​</br></br> ACHIEVE with all your might.'};
  }
  function createMarkup2() {
    return {__html: 'Ananse Hilltop RESIDENTIAL</br>FOOTBALL ACADEMY</br> Try-outs Limited Spots available</br>Ages:​</br>9-10​</br>11-12​</br>13-14'};
  }
  console.log('****************landing');

  console.log(this.props);
  console.log('*3333***************landing');

const abc=  this.props.testr.Landing

console.log(typeof abc)

console.log(abc)
console.log(this.vals(this.props.testr))

if( this.props.testr.Landing) {
   console.log("first element of tutors array",  this.props.testr.Landing[0])
   // anything you can acccess now of this element
   //const graphImage = require(this.props.testr.Landing[0].ddnHero.image.src)

}
   return(


    <div>

      <ReactSwipe
        className="carousel"
        swipeOptions={{ continuous: false,auto: 3000 }}
        ref={el => (reactSwipeEl = el)}
      >
        <div>


    {
      // if there is a ddnHero build it

      this.props.testr.Landing &&
      this.ddnHero(this.props.testr.Landing[0].ddnHero.ddnTextHeadline,this.props.testr.Landing[0].ddnHero.image.src)


    }

    </div>

    <div>
    {
      // if there is a ddnHero build it

      this.props.testr.Landing &&
      this.ddnHero(this.props.testr.Landing[0].ddnHero.ddnTextHeadline,this.props.testr.Landing[0].ddnHero.image.src)


    }
    </div>

    </ReactSwipe>
    {
      // if there is a ddnbuttonContanier build it

      this.props.testr.Landing &&
      this.ddnbuttonContanier(this.props.testr.Landing[0].ddnbuttonContanier.ddnbutton.text,this.props.testr.Landing[0].ddnbuttonContanier.ddnbutton.href)


    }
    <br></br>
{/*
    <button onClick={() => reactSwipeEl.next()}>Next</button>
    <button onClick={() => reactSwipeEl.prev()}>Previous</button>
*/}
      <div className="App-content-landing-container" >
      {
        // if there is a ddnbuttonContanier build it

        //this.props.testr.Landing && this.ddnContent(this.props.testr.Landing[0].ddnContentContanier[0])
        ///this.ddnContent(this.props.testr.Landing[0].ddnContentContanier[0].ddnPromo.image.src, this.props.testr.Landing[0].ddnContentContanier[0].ddnPromoText.textHeadline,this.props.testr.Landing[0].ddnContentContanier[0].ddnPromoText.desicption)
        this.props.testr.Landing &&
        this.props.testr.Landing[0].ddnContentContanier.map(item =>{
          console.log("rrrrrrrrr"+item.ddnPromoText.textHeadline);
          this.props.testr.Landing &&
        this.ddnContent(item.ddnPromo.image.src,item.ddnPromoText.textHeadline, item.ddnPromoText.desicption)
        })
      }

        <div className="App-content-landing" >
          <div className="App-promo" >
            <img src={playingsoccer9} className="App-title-reg-landing" alt="WEB DDN TECHNOLOGY" />
          </div>
          <div className="App-promo-text" >
            <div className="text-headline" >
              Ananse FC
            </div>
            <p>
            Traditionally, Ananse is seen as a witty and creative trickster. The word ‘Ananse’ means spider in Akan, but the character is sometimes portrayed as a man. Folk stories of Ananse originated from the Ashanti tribe in Ghana and have been passed down from generation to generation. Ananse is one of the major figures in African folktale which paints various pictures of great imagination, creativity, self belief, wit and meaningful life lessons. There are endless possibilities when it comes to Ananse stories. What’s your Ananse story?
            </p>
          </div>
        </div>
        <hr/>
        <div className="App-content-landing" >
          <div className="App-promo-text" >
          <div className="text-headline" >
          </div>
          <p>
            AFC seeks to identify motivated student-athletes linving in Ghana. Our program provides academic mentoring, lessons on diet and nutrition, player development and reinforces the importance of physical exercise through soccer. We are committed to developing each young player to their fulliest potenital.          </p>
          </div>
          <div className="App-promo" >
            <img src={playingsoccer2} className="App-title-reg-landing" alt="WEB DDN TECHNOLOGY" />
          </div>
        </div>
        <hr/>
        <div className="App-content-landing" >
          <div  className="App-promo2">
          <img src={playingsoccer11} className="App-title-reg-landing" alt="WEB DDN TECHNOLOGY" />

          </div>
          <div className="App-promo-text" >
            <div className="text-headline" >
              DURING THE IDENTIFICATION PROCESS
            </div>
            <p>
            We evaluate all student-athletes in the AFC screening process on the following criteria: interviews, recommendations, athletic and academic assessment.
            </p>
          </div>

        </div>
        <hr/>
        <div className="App-content-landing" >
          <div className="App-promo-text" >
          <div className="text-headline"  dangerouslySetInnerHTML={createMarkup()} >
          </div>
          <p>
          </p>
          </div>
          <div className="App-promo" >
          <img src={playingsoccer14} className="App-title-reg-landing" alt="WEB DDN TECHNOLOGY" />
          </div>
        </div>

      </div>
    </div>
    )
  }
}
