import React, { Component }  from 'react'
import playingsoccer21 from '../images/16513578485_585cc3ee4c_b.jpg';
import playingsoccer22 from '../images/ghana-362338_960_720.jpg';



const element = "Design is the creation of a plan or convention for the construction of a web site. Design involve considerable research, thought, modeling, interactive adjustment, and re-design."
export default class Football extends Component {



  render(){
    function createMarkup() {
      return {__html: 'IMAGINE with all your mind.</br></br> BELIEVE with all your heart.​</br></br> ACHIEVE with all your might.'};
    }
    return(
      <div className="App-content-landing-container" >
      <div className="App-content-landing" >
        <div className="App-promo" >
          <img src={playingsoccer22} className="App-title-reg-landing" alt="WEB DDN TECHNOLOGY" />
        </div>
        <div className="App-promo-text" >
          <div className="text-headline" >
            Football
          </div>
          <p>
          Football is by some distance the most popular sport in Ghana. Youth from all ages engage in football, whether in the comforts of their home, the neighborhood park, or their local team. It is a rite of passage for every Ghanaian. The passion of the game extends to the national level when Ghana’s many national sides (e.g. Black Stars, Black Starlets) compete in international tournaments. The entire nation comes to a standstill. It is through this national spirit that we  enjoy giving back to our motherland through the game of football. As ex- professionals football players we've found passion in giving back to young players and their families. In 2018 our passion pushed us further to form a strong partnership in Ghana with a prestige  private international school called The Hilltop School in Kumasi. We've combined education and football to provide more opportunities for Ghanaian youth to potentially earn scholarship opportunities through football.
          </p>
        </div>
      </div>
      <hr/>
      <div className="App-content-landing" >
        <div className="App-promo-text" >
        <div className="text-headline" >
        </div>
        <p>
          Over the years we've built strong relationships with coaches and  national acclaim clubs throughout the United States. We are a premier youth football development program that has a proven record of recruiting, developing, and propelling youth players to the next level, whether in preparatory schools, college or the professional ranks. </p>
        </div>
        <div className="App-promo" >
          <img src={playingsoccer21} className="App-title-reg-landing" alt="WEB DDN TECHNOLOGY" />
        </div>
      </div>
      </div>
    )
  }
}
