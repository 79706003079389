import React, { Component }  from 'react'
import logo from '../logo.svg';
import webddn from '../WEBDDN.svg';
import webddntech from '../images/DDNTECHNOLOGY2.png';

export default class About extends Component {

/*  constructor(props) {


    super(props)
  }*/
render(){
/* const Design = () => {

    const element = "Design is the creation of a plan or convention for the construction of a web site. Design involve considerable research, thought, modeling, interactive adjustment, and re-design."
    */
    return(<div>ss
      <img src={logo} className="App-logo" alt="logo" />

       <img src={webddn} className="App-title" alt="logo" />

       <img src={webddntech} className="App-title-reg" alt="WEB DDN TECHNOLOGY" />
     {window.location.pathname}-{this.props.location.pathname}
      {this.props.location.pathname} </div>)
}
  }
