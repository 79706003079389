import React, { Component }  from 'react'
import playingsoccer22 from '../images/ghana-362338_960_720.jpg';

export default class Mission extends Component {



  render(){
    function createMarkup() {
      return {__html: 'IMAGINE with all your mind.</br></br> BELIEVE with all your heart.​</br></br> ACHIEVE with all your might.'};
    }
    return(
      <div className="App-content-landing-container" >
      <div className="App-content-landing" >
        <div className="App-promo" >
          <img src={playingsoccer22} className="App-title-reg-landing" alt="WEB DDN TECHNOLOGY" />
        </div>
        <div className="App-promo-text" >
          <div className="text-headline" >
          Mission statement
          </div>
          <p>
          Ananse FC supports the physical and socioeconomical growth & development of Ghanaian youth living in Ghana by way of combining education and football (soccer) to create opportunities for potential athletic/academic scholarships to school in the United States
          </p>
        </div>
      </div>

      </div>
    )
  }
}
