import React, { Component }  from 'react'
import playingsoccer23 from '../images/Andre+Ayew+USA+v+Ghana+2010+FIFA+World+Cup+htKUxEf7oAjl.jpg';
import playingsoccer24 from '../images/Waris-scored-for-Lorient.jpg';
import playingsoccer25 from '../images/20150331_Mali_vs_Ghana_157.jpg';

export default class Academics extends Component {



  render(){
    function createMarkup() {
      return {__html: 'IMAGINE with all your mind.</br></br> BELIEVE with all your heart.​</br></br> ACHIEVE with all your might.'};
    }
    return(
      <div className="App-content-landing-container" >
      <div className="App-content-landing" >
        <div className="App-promo" >
          <img src={playingsoccer23} className="App-title-reg-landing" alt="WEB DDN TECHNOLOGY" />
        </div>
        <div className="App-promo-text" >
          <div className="text-headline" >
          Ananse Hilltop residential Academy

          </div>
          <p>
          Ananse Football Club Residential Football Academy is an independent education and football non-profit program in Kumasi Ghana.  It is offered to selected residential student athletes as an extracurricular potential opportunity. We help enroll highly motivated male and female student athletes who share a common goal – to obtain a premier preparatory-college education in the United States and United Kingdom. Our elite and competitive program aims to help find potential football scholarship opportunities for under privileged children in Ghana.
          </p>
        </div>
      </div>
      <hr/>
      <div className="App-content-landing" >
        <div className="App-promo-text" >
        <div className="text-headline" >
        </div>
        <p>
        AFC Residential Academy is the first residential soccer academy of  it's kind to combine education and football to find potential scholarship opportunities for under privileged children in Kumasi Ghana. Student-athletes selected for the Residential Academy attend The Hilltop School, participate in daily school activities and soccer-related activities throughout the academic year.
        </p>
        </div>
        <div className="App-promo" >
          <img src={playingsoccer24} className="App-title-reg-landing" alt="WEB DDN TECHNOLOGY" />
        </div>
      </div>
      <div className="App-content-landing" >
        <div className="App-promo" >
          <img src={playingsoccer25} className="App-title-reg-landing" alt="WEB DDN TECHNOLOGY" />
        </div>
        <div className="App-promo-text" >
          <div className="text-headline" >
          Ananse Hilltop residential Academy

          </div>
          <p>
          We believe in developing players with good character and strong values. Beyond the field, our players are committed to excellence in the classroom and throughout their school communities.
          </p>
        </div>
      </div>
      <hr/>
      <div className="App-content-landing" >
        <div className="App-promo-text" >
        <div className="text-headline" >
        Imagine. Believe. Achieve


        </div>
        <p>
        </p>
        </div>

      </div>
      </div>
    )
  }
}
