import React, { Component } from 'react';
import axios from 'axios';
import localconfig from './data/localConfig';

//import logo from './logo.svg';
//import webddn from './WEBDDN.svg';
//import webddntech from './images/DDNTECHNOLOGY2.png';
import {withRouter} from 'react-router-dom';

import './App.css';
import Main from './Main'
import Nav from './components/Nav'
import Footer from './components/Footer'
import playingsoccer from './images/soccer.mp4';


const NavWithRouter = withRouter(Nav);

class App extends Component {
  state = {
    ddnconfig: [],
    isLoading: true,
    errors: null
  };
  componentWillReceiveProps(nextProps) {
    if (nextProps.location !== this.props.location) {
      // navigated!
    }
  }
  componentDidMount(){
    const url = './data/localConfig.json';
    //var jsonData = require(url);
    console.log('ssssddnconfig')

    //require(url);
    let ddnconfig;
    //var myObject = JSON.parse(myjsonstring);
    axios
      .get(url)
      .then(response =>
      {
        //ddnconfig = JSON.parse(response.data)
        //ddnconfig = response.data
            console.log('2222222222222223ddnconfig[0]')
                console.log(response.data.Landing[0])
                let abcstr2 = JSON.stringify(response.data)
                console.log(abcstr2)
        this.setState({
          ddnconfig: response.data,
          isLoading: false
        })
      }

      )
      .catch(error => this.setState({error, isLoading:false}))

  }
  render() {

    const { isLoading, ddnconfig } = this.state
    console.log(this.state)
    console.log(this.state.ddnconfig)
    console.log('0000000000this.state.ddnconfig[0]')

    console.log(this.state.ddnconfig)

    console.log('App ddnconfig render')
    //var ddnconfigJson = JSON.parse(ddnconfig);
    console.log(ddnconfig.Landing)
    console.log('this.props.location.pathname')

    console.log(this.props.location.pathname)

  if(this.props.location.pathname ==="/")
  {
    console.log('this.props.location.pathname')
    console.log(ddnconfig)
    return (

      <div className="App">
      <video className="fullscreen-bg"  autoplay="true" loop muted>
        <source src={playingsoccer} type="video/mp4" />
      </video>
        <header className="App-header-landing">
          <NavWithRouter title="Navigation" />
        </header>

        <Main ddnstyle="App-main-landing" ddnLanding={ddnconfig} />
        <Footer />
      </div>
    );
  }
  else {


      return (


        <div className="App">
        <video className="fullscreen-bg"  autoplay="true" loop muted>
          <source src={playingsoccer} type="video/mp4" />
        </video>
        <header className="App-header-landing">
          <NavWithRouter title="Navigation" />
        </header>
          <Main ddnstyle="App-main-landing" ddnLanding={ddnconfig} />
            <Footer />
        </div>
      );
    }
  }
}

export default withRouter(App);
