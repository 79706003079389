import React, { Component }  from 'react'
import playingsoccer22 from '../images/Adidas_wawa_aba.jpg';

export default class Vision extends Component {



  render(){
    function createMarkup() {
      return {__html: 'IMAGINE with all your mind.</br></br> BELIEVE with all your heart.​</br></br> ACHIEVE with all your might.'};
    }
    return(
      <div className="App-content-landing-container" >
      <div className="App-content-landing" >
        <div className="App-promo" >
          <img src={playingsoccer22} className="App-title-reg-landing" alt="WEB DDN TECHNOLOGY" />
        </div>
        <div className="App-promo-text" >
          <div className="text-headline" >
            Vision statement

          </div>
          <p>
          Ananse FC aims to use football (soccer) the #1 sport in Ghana as a platform and vehicle to identify and place talented student athletes abroad. With core values such as leadership, perseverance, discipline and respect instilled through the game of soccer AFC envisions Ghana over the next 10 years as a country where unlimited opportunities are present for football playing and academically talented youth to achieve their goals.
          </p>
        </div>
      </div>

      </div>
    )
  }
}
